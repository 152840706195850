import * as React from 'react';
import { AppRootProps } from '@grafana/data';
//import { testIds } from '../testIds';
//import { getBackendSrv } from '@grafana/runtime';
import { getLocationSrv } from '@grafana/runtime';

export class App extends React.PureComponent<AppRootProps> {
  componentDidMount() {
    getLocationSrv().update({ path: '/d/eficacia/reporte-general' });
  }

  render() {
    return null;
  }
}
